import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { useResponsive } from '@2ndmarket/hooks';
import { Icon, themes } from '@2ndmarket/components';
import { Targets, MenuDashboard } from '@2ndmarket/helpers';
import { IconFormat } from '@2ndmarket/components/src/Icon/Icon';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DrawerMenu from '../DrawerMenu';
import DrawerProfile from '../DrawerProfile';

const Header: React.FC = () => {
  const router = useRouter();
  const mediumUp = useResponsive('up', 'md', 'sm');

  return (
    <Box
      width="100%"
      height="72px"
      paddingX={3}
      display="flex"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      sx={{
        backgroundColor:
          themes.authentication.palette.primary.main,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
      }}
    >
      <Box
        width="100%"
        paddingX={2}
        height="80px"
        marginTop={8}
        maxWidth="956px"
        display="flex"
        flexShrink={0}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderRadius: '6px',
          backgroundColor:
            themes.authentication.palette.common.white,
        }}
      >
        <NextLink href={Targets.ROOT} passHref>
          <Link>
            <Box>
              <Icon
                size={28}
                name="logo-2nd"
                format={IconFormat.SVG}
                color={
                  themes.authentication.palette.primary.main
                }
              />
            </Box>
          </Link>
        </NextLink>
        <Stack
          sx={{
            gap: {
              xs: 2,
              sm: 2,
              md: 5,
              lg: 5,
            },
          }}
          spacing={2}
          direction="row"
          textAlign="center"
          alignItems="center"
        >
          {mediumUp ? (
            MenuDashboard.map((item, index) => (
              <NextLink
                href={item.target}
                scroll={false}
                key={index}
                passHref
              >
                <Typography
                  component="a"
                  variant="body1"
                  color={
                    router.pathname == item.target
                      ? themes.authentication.palette.primary
                          .main
                      : themes.authentication.palette.grey[700]
                  }
                  sx={{
                    marginLeft: '0 !important',
                    '&:hover': {
                      color:
                        themes.authentication.palette.primary
                          .main,
                    },
                  }}
                >
                  {item.label}
                </Typography>
              </NextLink>
            ))
          ) : (
            <DrawerMenu />
          )}
          <DrawerProfile />
        </Stack>
      </Box>
    </Box>
  );
};

export default Header;
