import { useMe } from '@2ndmarket/services';
import { ReviewStep } from '@2ndmarket/helpers';
import { Icon, themes } from '@2ndmarket/components';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

const AlertOnboarding: React.FC = () => {
  const { data: me } = useMe();

  for (const [key, value] of Object.entries(
    me.onboarding_basic,
  )) {
    if (key === 'status_message') {
      if (value != '') {
        return (
          me.onboarding_basic.status_message && (
            <Alert
              severity="error"
              icon={<Icon name="close" size={18} />}
              sx={{
                border: 1,
                marginBottom: 4,
                display: 'flex',
                alignItems: 'center',
                borderColor:
                  themes.authentication.palette.error.main,
              }}
            >
              <Typography variant="body2" component="p">
                {me.onboarding_basic.status_message}
              </Typography>
            </Alert>
          )
        );
      }
    }
  }

  return null;
};

export default AlertOnboarding;
