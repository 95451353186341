import React from 'react';
import { Icon, themes } from '@2ndmarket/components';
import { IconFormat } from '@2ndmarket/components/src/Icon/Icon';

import { useResponsive } from '@2ndmarket/hooks';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface RoutineProps {
  removeBackground?: any;
  full?: boolean;
  access?: boolean;
}

const Routine: React.FC<RoutineProps> = ({
  removeBackground,
  full,
  access,
}) => {
  const mediumUp = useResponsive('up', 'md', 'sm');
  removeBackground = useResponsive('up', 'sm', 'xs');

  return (
    <Grid
      item
      paddingY={8}
      paddingX="9%"
      md={full ? 12 : 7}
      sm={full ? 12 : 3}
      xs={full ? 12 : false}
      sx={{
        backgroundImage: 'url(/background.mui.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: {
          sm: 'block',
          xs: `${full ? 'block' : 'none'}`,
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'grid',
          alignItems: 'center',
          gridTemplateRows: 'auto 1fr',
        }}
      >
        <Box
          sx={{
            alignSelf: 'start',
            maxWidth: '520px',
          }}
        >
          <Icon
            size={28}
            name="logo-2nd"
            format={IconFormat.SVG}
            color={themes.authentication.palette.common.white}
          />
        </Box>
        {(mediumUp || access) && (
          <Box
            sx={{
              mx: 'auto',
              maxWidth: '440px',
              alignSelf: 'center',
            }}
          >
            <Typography
              variant="h1"
              component="h1"
              marginBottom={5}
              color={themes.authentication.palette.common.white}
            >
              Complete o seu cadastro para abrir a{' '}
              <span style={{ textDecoration: 'underline' }}>
                conta digital
              </span>
            </Typography>
            <Typography
              component="p"
              variant="body1"
              marginBottom={2}
              color={themes.authentication.palette.common.white}
            >
              Realize transferências entre contas Bank.ai,
              depósitos ou recebimentos de eventuais contas que
              você possua em outras instituições e estejam
              vinculadas ao seu CPF.
            </Typography>
            <Typography
              component="p"
              variant="body1"
              marginBottom={2}
              color={themes.authentication.palette.common.white}
            >
              Mobilidade financeira como você nunca viu.
            </Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default Routine;
