import NextLink from 'next/link';
import { useState } from 'react';
import { useRouter } from 'next/router';

import { Icon, themes } from '@2ndmarket/components';
import { MenuDashboard } from '@2ndmarket/helpers';

import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { DrawerHeader } from './styles';

const DrawerMenu: React.FC = () => {
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const toogleDrawerMenu = (open: boolean) => () => {
    setOpen(open);
  };

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toogleDrawerMenu(true)}
      >
        <Icon
          name="menu"
          size={20}
          color={themes.authentication.palette.grey[700]}
        />
      </IconButton>
      <Drawer
        open={open}
        anchor="right"
        role="presentation"
        onClose={toogleDrawerMenu(false)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor:
              themes.authentication.palette.grey[900],
          },
        }}
      >
        <DrawerHeader sx={{ width: 315 }}>
          <Stack
            gap={12}
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton onClick={() => setOpen(false)}>
              <Icon
                size={15}
                name="close"
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
            <Typography
              variant="h4"
              component="h4"
              color="text.primary"
            >
              Menu
            </Typography>
          </Stack>
        </DrawerHeader>
        <List
          component="nav"
          sx={{ width: '100%', flexDirection: 'column' }}
        >
          {MenuDashboard.map((item, index) => (
            <ul key={index}>
              <Divider variant="middle" />
              <ListItem sx={{ paddingY: 2 }}>
                <NextLink href={item.target} passHref>
                  <Typography
                    component="a"
                    variant="body1"
                    color={
                      router.pathname == item.target
                        ? themes.authentication.palette.primary
                            .main
                        : themes.authentication.palette.grey[700]
                    }
                    sx={{ width: '100%' }}
                  >
                    {item.label}
                  </Typography>
                </NextLink>
              </ListItem>
            </ul>
          ))}
          <Divider variant="middle" />
        </List>
      </Drawer>
    </>
  );
};

export default DrawerMenu;
