import { SEO } from '@2ndmarket/components';

interface Props {
  title: string;
  description: string;
}

const DashboardSEO: React.FC<Props> = ({
  title,
  description,
}) => {
  const url = process.env.NEXT_PUBLIC_DASHBOARD_URL;

  const params = {
    appName: '2ND Market',
    title: title,
    description: description,
    image: url + '/banner.jpg',
    imageWidth: '1080',
    imageHeight: '1080',
    themeColor: '#313EE2',
    url: url,
  };

  return <SEO {...params}></SEO>;
};

export default DashboardSEO;
