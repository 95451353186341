import { useState, useEffect, useRef } from 'react';

import {
  Masks,
  HttpError,
  HttpStatus,
  OnboardingProgress,
  ReviewStep,
} from '@2ndmarket/helpers';

import {
  Icon,
  themes,
  MuiLoader,
  ModalDialog,
  MaskedInput,
} from '@2ndmarket/components';

import {
  useMe,
  fiatVerify,
  fiatRequest,
} from '@2ndmarket/services';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import { TextField } from '@mui/material';

const DigitalAccountOpening: React.FC = () => {
  const { data: me, mutate } = useMe();

  const [code, setCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [codeSent, setCodeSent] = useState(false);
  const [countDown, setCountDown] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const timerRef = useRef<HTMLButtonElement>(null);

  // useEffect(() => {
  //   if (
  //     me.onboarding_digital_account.status == ReviewStep.PENDING
  //   ) {
  //     setCodeSent(true);
  //     setCountDown(false);
  //   }
  // }, [me]);

  const resquestFiat = () => {
    setCountDown(true);
    setCodeSent(true);
    setErrorCode('');

    localStorage.setItem('@2ndMarket-success-message', 'true');

    fiatRequest()
      .then(() => {
        mutate();
      })
      .catch((error: HttpError) => {
        setErrorMessage(error.error);
        setErrorModal(true);
      });
  };

  const verifyCode = () => {
    if (code == '') {
      setErrorCode('Código é obrigatório');
      return;
    }

    fiatVerify(code)
      .then(() => {
        mutate();
      })
      .catch((error: HttpError) => {
        if (
          error.status ===
          HttpStatus.ClientErrorUnprocessableEntity
        ) {
          for (let fieldError of error.errors) {
            setErrorCode(fieldError.message);
          }
        } else {
          setErrorMessage(error.error);
          setErrorModal(true);
        }
      })
      .finally(() => {});
  };

  const handleCountDown = () => {
    let timer = 120;
    const intervalId = window.setInterval(() => {
      if (timerRef.current) {
        if (timer <= 0) {
          clearInterval(intervalId);
          timerRef.current.textContent = '';
          setCodeSent(false);
          setCountDown(false);
          return;
        }

        let initialMinutes = String(timer / 60);
        let initialSeconds = String(timer % 60);
        let minutes = parseInt(initialMinutes, 10);
        let seconds = parseInt(initialSeconds, 10);

        timerRef.current.textContent =
          ('0' + minutes).slice(-2) +
          ':' +
          ('0' + seconds).slice(-2);
        timer--;
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  };

  const toggleValue = (value: string) => {
    const newCode = parseInt(value.replace(/\D/g, ''));
    if (isNaN(newCode)) {
      setCode('');
    } else {
      setCode(value);
    }
  };

  useEffect(() => {
    if (!countDown) return;
    handleCountDown();
  }, [countDown]);

  return (
    <>
      {OnboardingProgress.BASIC == me.onboarding_progress ? (
        <Box width="100%" display="flex" marginBottom={5}>
          <Card
            sx={{
              gap: 2,
              width: '100%',
              display: 'flex',
              paddingY: '32px',
              paddingX: '26px',
              boxShadow: 'none',
              alignItems: {
                xs: 'center',
                sm: 'center',
                md: 'flex-start',
                lg: 'flex-start',
              },
              flexDirection: {
                xs: 'column',
                sm: 'row',
                md: 'row',
                lg: 'row',
              },
              justifyContent: {
                xs: 'center',
                sm: 'space-between',
                md: 'space-between',
                lg: 'space-between',
              },
              backgroundColor:
                themes.authentication.palette.common.white,
            }}
          >
            <CardHeader
              sx={{ padding: 0 }}
              avatar={
                codeSent ||
                me.onboarding_digital_account.status ==
                  ReviewStep.PENDING ? (
                  <Avatar
                    sx={{
                      width: 60,
                      height: 60,
                      backgroundColor:
                        themes.authentication.palette.primary
                          .main,
                    }}
                  >
                    <Icon
                      size={20}
                      name="phone"
                      color={
                        themes.authentication.palette.common
                          .white
                      }
                    />
                  </Avatar>
                ) : (
                  <Avatar
                    sx={{
                      width: 60,
                      height: 60,
                      backgroundColor:
                        themes.authentication.palette.grey[200],
                    }}
                  >
                    <Icon
                      size={20}
                      name="phone"
                      color={
                        themes.authentication.palette.primary
                          .main
                      }
                    />
                  </Avatar>
                )
              }
              title={
                <Typography
                  component="h4"
                  variant="h4"
                  color={themes.authentication.palette.grey[700]}
                >
                  {codeSent ||
                  me.onboarding_digital_account.status ==
                    ReviewStep.PENDING
                    ? 'Código enviado!'
                    : 'Abra sua conta digital!'}
                </Typography>
              }
              subheader={
                <Typography
                  component="p"
                  variant="body1"
                  color={themes.authentication.palette.grey[600]}
                >
                  {codeSent ||
                  me.onboarding_digital_account.status ==
                    ReviewStep.PENDING
                    ? 'Verifique o código no seu celular.'
                    : 'Crie a sua conta para aproveitar os recursos!'}
                </Typography>
              }
            />
            <CardActions
              sx={{
                justifyContent: 'space-around',
                padding: 0,
              }}
            >
              {codeSent ||
              me.onboarding_digital_account.status ==
                ReviewStep.PENDING ? (
                <Box
                  gap={1}
                  display="flex"
                  sx={{
                    flexDirection: {
                      xs: 'column',
                      sm: 'column',
                      md: 'row',
                      lg: 'row',
                    },
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: 'auto',
                      lg: 'auto',
                    },
                  }}
                >
                  <TextField
                    name="code"
                    size="medium"
                    margin="dense"
                    label="Código"
                    InputProps={{
                      inputComponent: MaskedInput,
                      inputProps: {
                        inputMode: 'numeric',
                        mask: Masks.CODE,
                      },
                    }}
                    error={errorCode != ''}
                    helperText={errorCode}
                    onChange={event =>
                      toggleValue(event.target.value)
                    }
                    sx={{
                      marginTop: 0,
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 200,
                        lg: 200,
                      },
                    }}
                  />
                  {!countDown ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: 136,
                          lg: 136,
                        },
                        height: 56,
                      }}
                      onClick={resquestFiat}
                    >
                      Reenviar
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: 136,
                          lg: 136,
                        },
                        height: 56,
                      }}
                      disabled
                      ref={timerRef}
                    />
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={verifyCode}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 136,
                        lg: 136,
                      },
                      height: 56,
                    }}
                  >
                    Validar
                  </Button>
                </Box>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={resquestFiat}
                  sx={{
                    width: 172,
                    marginTop: {
                      xs: 2,
                      sm: 0,
                      md: 0,
                      lg: 0,
                    },
                  }}
                >
                  Enviar código
                </Button>
              )}
            </CardActions>
          </Card>
        </Box>
      ) : null}

      {errorModal && (
        <ModalDialog
          maxWidth="sm"
          open={errorModal}
          errorMessage={errorMessage}
          onClose={() => setErrorModal(false)}
          errorTitle="Problema ao enviar código"
        />
      )}
    </>
  );
};

export default DigitalAccountOpening;
