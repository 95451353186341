import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';

import { useMe } from '@2ndmarket/services';
import { ReviewStep, Targets } from '@2ndmarket/helpers';
import { Icon, themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';

import { styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon';

const steps = [
  {
    label: 'Meus dados',
    key: 'profile_step',
    uuid: 0,
  },
  {
    label: 'Endereço',
    key: 'address_step',
    uuid: 1,
  },
  {
    label: 'Documento',
    key: 'document_step',
    uuid: 2,
  },
  {
    label: 'Telefone',
    key: 'phone_step',
    uuid: 3,
  },
  {
    label: 'Senha',
    key: 'pin_step',
    uuid: 4,
  },
];

const Steppers: React.FC = () => {
  const router = useRouter();
  const { data: me } = useMe();

  const [activeStep, setActiveStep] = useState<number>();

  const isStepFailed = (steps: any) => {
    for (const [key, value] of Object.entries(
      me.onboarding_basic,
    )) {
      if (steps.key === key) {
        if (value === ReviewStep.REFUSED) {
          return true;
        }
      }
    }

    return false;
  };

  const StepAvatar = styled(Avatar)<{
    ownerState: {
      active?: boolean;
      completed?: boolean;
      error?: boolean;
    };
  }>(({ theme, ownerState }) => ({
    width: 46,
    height: 46,
    ...(ownerState.active && {
      backgroundColor: theme.palette.primary.main,
    }),
    ...(ownerState.completed && {
      backgroundColor: theme.palette.primary.main,
    }),
    ...(ownerState.error && {
      backgroundColor: theme.palette.grey[800],
    }),
  }));

  const StepIcons = (props: StepIconProps) => {
    const { active, completed, error } = props;
    const icons: { [index: string]: ReactElement } = {
      1: <Icon size={18} name="user" />,
      2: <Icon size={18} name="fixed" />,
      3: <Icon size={18} name="list" />,
      4: <Icon size={18} name="phone" />,
      5: <Icon size={18} name="key" />,
    };

    return (
      <StepAvatar ownerState={{ active, completed, error }}>
        {icons[String(props.icon)]}
      </StepAvatar>
    );
  };

  useEffect(() => {
    switch (router.pathname) {
      case Targets.ONBOARDING_DATA:
        setActiveStep(steps[0].uuid);
        break;
      case Targets.ONBOARDING_ADDRESS:
        setActiveStep(steps[1].uuid);
        break;
      case Targets.ONBOARDING_DOCUMENT:
        setActiveStep(steps[2].uuid);
        break;
      case Targets.ONBOARDING_PHONE:
        setActiveStep(steps[3].uuid);
        break;
      case Targets.ONBOARDING_PASSWORD:
        setActiveStep(steps[4].uuid);
        break;
    }
  }, [router]);

  return (
    <Box
      paddingBottom={4}
      justifyItems="center"
      borderBottom={`1px solid ${themes.authentication.palette.grey[200]}`}
      sx={{
        paddingX: {
          xs: 1,
          sm: 2,
          md: 1,
          lg: 3,
        },
      }}
    >
      <Stack spacing={4} sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => {
            const labelProps: {
              error?: boolean;
            } = {};

            if (isStepFailed(step)) {
              labelProps.error = true;
              if (activeStep === undefined) {
                setActiveStep(step.uuid);
              }
            }

            return (
              <Step
                key={step.uuid}
                className={
                  labelProps.error ? 'MuiStep-error' : ''
                }
              >
                <StepLabel
                  {...labelProps}
                  StepIconComponent={StepIcons}
                >
                  {step.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Stack>
    </Box>
  );
};

export default Steppers;
