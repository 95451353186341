import NextLink from 'next/link';

import { Icon, themes } from '@2ndmarket/components';
import { IconFormat } from '@2ndmarket/components/src/Icon/Icon';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import Products from '../../helper/products';

const CardsProducts: React.FC = () => {
  const activeProducts = Products.filter(
    product => product.card,
  );

  return (
    <Box
      gap={3}
      width="100%"
      display="flex"
      sx={{
        flexDirection: {
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
        },
      }}
    >
      {activeProducts.map((product: any, index: number) => (
        <Box key={index}>
          <NextLink href={product.destination} passHref>
            <Link
              target="_blank"
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              <Card
                sx={{
                  padding: 2,
                  boxShadow: 'none',
                  borderRadius: '6px',
                  '&:hover': {
                    boxShadow:
                      '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
                  },
                }}
              >
                <CardHeader
                  sx={{ padding: 0 }}
                  avatar={
                    <Avatar
                      aria-label={product.name}
                      sx={{ backgroundColor: product.color }}
                    >
                      <Icon
                        size={20}
                        name="logo-2nd"
                        viewBox="0 0 28 28"
                        format={IconFormat.SVG}
                      />
                    </Avatar>
                  }
                  title={
                    <Typography component="h4" variant="h4">
                      {product.name}
                    </Typography>
                  }
                  subheader={
                    <Typography
                      component="p"
                      variant="body1"
                      color={
                        themes.authentication.palette.grey[300]
                      }
                    >
                      {product.infos}
                    </Typography>
                  }
                />
                <CardContent sx={{ paddingX: 0 }}>
                  <Typography
                    component="p"
                    variant="body1"
                    color="text.secondary"
                  >
                    {product.text}
                  </Typography>
                </CardContent>
                <CardActions sx={{ paddingX: 0 }}>
                  <Box
                    gap={1}
                    display="flex"
                    alignItems="center"
                    color={product.color}
                  >
                    <Typography
                      component="p"
                      variant="body1"
                      sx={{ textDecoration: 'underline' }}
                    >
                      Acessar
                    </Typography>
                    <Icon name="external-link" size={12} />
                  </Box>
                </CardActions>
              </Card>
            </Link>
          </NextLink>
        </Box>
      ))}
    </Box>
  );
};

export default CardsProducts;
