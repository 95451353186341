import { useState } from 'react';

import { Icon, themes } from '@2ndmarket/components';
import { getInitials } from '@2ndmarket/helpers';
import { useMe } from '@2ndmarket/services';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { DrawerHeader } from './styles';
import ListProducts from '../ListProduct';
import Products from '../../helper/products';

const DrawerProfile: React.FC = () => {
  const { data: me } = useMe();
  const [open, setOpen] = useState(false);

  const toogleDrawerProfile = (open: boolean) => () => {
    setOpen(open);
  };

  const logout = () => {
    return (window.location.href =
      process.env.NEXT_PUBLIC_LOGIN_URL + '/logout');
  };

  return (
    <>
      <Avatar
        sx={{
          width: 46,
          height: 46,
          cursor: 'pointer',
          marginLeft: '0 !important',
          bgcolor: themes.authentication.palette.primary.main,
        }}
        onClick={toogleDrawerProfile(true)}
      >
        <Typography
          variant="body1"
          onClick={toogleDrawerProfile(true)}
        >
          {getInitials(me.name)}
        </Typography>
      </Avatar>
      <Drawer
        open={open}
        anchor="right"
        role="presentation"
        onClose={toogleDrawerProfile(false)}
        sx={{
          '.MuiPaper-root': {
            backgroundColor:
              themes.authentication.palette.common.white,
          },
        }}
      >
        <DrawerHeader
          sx={{
            width: {
              xs: 315,
              sm: 415,
              md: 415,
              lg: 415,
            },
          }}
        >
          <Stack
            gap={6}
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton onClick={() => setOpen(false)}>
              <Icon
                size={15}
                name="close"
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
            <Typography
              variant="h4"
              component="h4"
              color="text.primary"
              sx={{ width: '100%', textAlign: 'center' }}
            >
              Perfil
            </Typography>
          </Stack>
        </DrawerHeader>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Avatar
            sx={{
              width: 110,
              height: 110,
              marginLeft: '0 !important',
            }}
          >
            <Typography
              variant="subtitle1"
              color={themes.authentication.palette.primary.main}
            >
              {getInitials(me.name)}
            </Typography>
          </Avatar>
          <Typography
            component="p"
            marginTop={2}
            variant="subtitle1"
          >
            Olá, {me.name}!
          </Typography>
          <Typography
            component="p"
            variant="body1"
            marginBottom={4}
            color={themes.authentication.palette.grey[300]}
          >
            {me.email}
          </Typography>
        </Box>
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          sx={{
            backgroundColor:
              themes.authentication.palette.grey[400],
          }}
        >
          <Typography
            component="p"
            variant="body1"
            sx={{ paddingX: 3, pt: 4, pb: 2 }}
            color={themes.authentication.palette.grey[300]}
          >
            Ecossistema
          </Typography>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <ListProducts
              products={Products}
              selectedColor="common.white"
            />
            <Button
              type="button"
              color="secondary"
              variant="contained"
              onClick={() => logout()}
              sx={{
                marginX: 3,
                marginBottom: 4,
                boxShadow: 'none',
                width: 'calc(100% - 48px)',
              }}
            >
              Sair da conta
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerProfile;
