import { useState } from 'react';
import { useLocalStorage } from '@2ndmarket/hooks';
import { ProductsListProps } from '@2ndmarket/types';
import { Icon, themes } from '@2ndmarket/components';
import {
  LocalStorage,
  ProductsProps,
  Product,
} from '@2ndmarket/helpers';
import { IconFormat } from '@2ndmarket/components/src/Icon/Icon';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

const ListProducts: React.FC<ProductsListProps> = ({
  products,
  selectedColor,
}) => {
  const Products = products as any;

  const [token] = useLocalStorage(LocalStorage.JWT, '');
  const [userId] = useLocalStorage(LocalStorage.USER_ID, '');
  const [storedProduct, setStoredProduct] = useLocalStorage(
    LocalStorage.PRODUCT,
    '',
  );

  const switchProduct = (product: ProductsProps) => {
    window.location.href = product.destination + ``;
  };

  return (
    <Box display="flex" flexDirection="column">
      <List
        aria-label="contacts"
        sx={{
          gap: 2,
          marginX: 2,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {Object.keys(Products).map((item, index) => {
          return Products[item].active ? (
            <ListItemButton
              key={index}
              selected={Products[item].key == Product.DASHBOARD}
              sx={{
                width: '100%',
                '&.Mui-selected': {
                  backgroundColor: selectedColor,
                },
                '&:hover': {
                  backgroundColor: selectedColor,
                },
              }}
              onClick={() => {
                switchProduct(Products[item]);
              }}
            >
              <ListItemAvatar sx={{ mr: '10px' }}>
                <Avatar
                  sx={{
                    backgroundColor: Products[item].color,
                  }}
                >
                  <Icon
                    size={17}
                    name="logo-2nd"
                    viewBox="0 0 28 28"
                    format={IconFormat.SVG}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={Products[item].name} />
              {Products[item].key == Product.DASHBOARD && (
                <Icon
                  name="check"
                  size={12}
                  color={
                    themes.authentication.palette.primary.main
                  }
                />
              )}
            </ListItemButton>
          ) : (
            <ListItemButton
              key={index}
              sx={{
                width: '100%',
                '&.Mui-selected': {
                  backgroundColor: 'grey.400',
                },
              }}
              disabled
            >
              <ListItemAvatar sx={{ mr: '10px' }}>
                <Avatar
                  sx={{
                    backgroundColor: 'grey.500',
                  }}
                >
                  <Icon
                    size={17}
                    name="logo-2nd"
                    viewBox="0 0 28 28"
                    format={IconFormat.SVG}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={Products[item].name} />
              <Typography
                component="span"
                variant="body2"
                sx={{
                  backgroundColor: 'grey.200',
                  padding: '6px 13px',
                  color: 'grey.600',
                }}
              >
                Em breve
              </Typography>
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
};

export default ListProducts;
